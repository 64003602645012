/*-----------------------------
    GENERAL
-------------------------------*/
@import 'design/template/mixin_templates';
@import 'design/variables';
@import url('https://fonts.googleapis.com/css?family=Great+Vibes|PT+Sans+Narrow');

@keyframes menu {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

iframe {
    max-width: 100%;
}

img {
    max-width: 100%;


}
.wrapper-content {
    /* Pour tinyMCE */
    height: auto !important;
}

body {
    background-repeat: repeat;
    margin: 0;
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 17px;
    overflow-x: hidden;
}

@include titreBaseSize();

#bloc-fil-title {
    padding-bottom: 5px;
}

#wrapper {
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 15px;
}

#pub {
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
}

.content {
    min-height: 400px;
    margin-bottom: 20px;
    position: relative;
}

.content .page, .album-photo, .boutique, .mode-boutique {
    &::after{
        content:'';
        display: block;
        clear: both;
        height: 5px;
        border-top:1px solid;
    }
    margin: 15px 0;
}

h2, .h2, .title_section {
    line-height: 40px;
    font-size: 30px;
    text-decoration: none;
    text-transform: uppercase;
    padding-bottom: 5px;
    margin-bottom: 0;
    margin-top: 24px;
    position: relative;

    &:not(.titre-article)::after {
        content: '';
        display: block;
        width: 100%;
        height: 5px;
        position: absolute;
        bottom: -7px;
    }

    :not(.title_section) {
        margin-bottom: 30px;
    }
}

.userdigit .button {
    position: relative;
    right: -35px;
    top: 1px;
}

.page {

    h2, .h2 {
        margin-bottom: 30px;
    }

    ul {
        list-style-type: circle;
    }
}

.content #fiche-produit .remise-produit {
    display: block;
    font-size: 20px;
    font-weight: bold;
    left: 0;
    line-height: 23px;
    padding: 3px 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 101;
}

.title_section {
    font-size: 30px;
    text-decoration: none;
    text-transform: uppercase;
    padding-bottom: 5px;
    margin-bottom: 0;
    margin-top: 24px;
}

.button {
    font-size: 1.1em;
    width: auto;
    display: inline-block;
    text-decoration: none;
    margin: 10px auto;
    vertical-align: middle;
    padding: 10px;
}

body #minisite-sidebar {
    z-index: 10000;
}

/*-----------------------------
    ENTETE DE PAGE

    /!\ Attention ce header a trop d'indentation, on arrive jusqu'a 6 indentation, c'est beaucoup trop !
-------------------------------*/
header {
    margin: 0 auto;
    min-height: 150px;
    width: 100%;
    z-index: 1000;
    position: relative;
    .header-conteneur {
        width: 1100px;
        margin: 0 auto;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &::after {
            content: "";
            display: block;
            clear: both;
        }

        #blocContent {
            position: absolute;
            width: 1100px;
            float: left;
            top: 0;
            bottom: 0;
            #headercontent {
                margin: 0 auto;
                position: relative;
                height: 100%;

                .logo {
                    display: inline-block;
                }

                .slogan {
                    font-family: 'Great Vibes', cursive;
                    font-size: 28px;
                    margin: 0;
                    z-index: 10;
                }
            }
        }

        .menu {
            //max-width: 70%;

        }
    }
}
// Correction à cause des indentation en trop
.header_live_edit .logo img {
    height: 70px;
    max-width: 100%;
}

/*-----------------------------
    NAVIGATION
-------------------------------*/

.cmonsite-panier-3 header .menu nav#nav-principal{
    > ul > li > a{
        padding-top:55px;
    }
}

header .menu {
    width: auto;
    //max-width: 60%;
    float: right;
    position: relative;
    z-index: 12;
    nav#nav-principal {
        clear: left;
        float: right;

        ul {

            ul {
                min-width: 190px;
                padding: 0;
                z-index: 1000;

                li > ul {
                    left: 95%;
                    padding: 0;
                    position: absolute;
                    top: -1px;
                }

                li:hover > ul {
                    left: 100%;
                    top: -1px;
                }

                ul:before {
                    border: 0;
                }

                ul li {
                    border-radius: 0;
                }
            }

            li {
                text-align: left;

                &:hover > ul {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                    animation-duration: .2s;
                    animation-name: menu;
                }

                a {
                    font-size: 16px;
                    font-weight: bold;
                    text-decoration: none;
                    padding: 9px 7px 53px;
                }

                ul li {
                    display: block;
                    float: none;
                    left: 0;
                    top: 0;
                    position: relative;

                    a {
                        display: block;
                        font-size: 15px;
                        line-height: 15px;
                        padding: 10px;
                        white-space: nowrap;
                    }
                }
            }
        }

        > ul > li {
            display: inline-block;
            position: relative;
            list-style: none outside none;
            margin-left: 10px;
            padding: 0;
            margin-bottom:5px;

            > a {
                padding: 40px 10px 15px;
                text-transform: uppercase;
            }

            ul {
                opacity: 0;
                position: absolute;
                left: 0;
                padding-top: 5px;
                z-index: 200;
            }
        }

        > ul {
            //margin-top: 60px;
            margin-top: 0;
            padding: 0;
            text-align: right;

            > li ul {
                visibility: hidden;
                display: none;
            }
        }
        ul li {

            a {
                display: block;
            }

            &:hover a:hover {
                transition: all .1s linear;
            }
        }
    }
}

// Fils d'arianne

nav.arianne {

    ul {
        list-style-type: none;
        margin: 0 0 20px;
        padding: 0;
        display: block;

        li {
            &:nth-child(2) a {
                padding: 5px 10px 5px 18px;
            }

            &:first-child a {
                margin: 0 10px 0 0;

                &:after {
                    border-bottom: 15px solid rgba(0, 0, 0, 0);
                    border-left: 10px solid;
                    border-top: 15px solid rgba(0, 0, 0, 0);
                    content: "";
                    height: 0;
                    right: -10px;
                    position: absolute;
                    top: 0;
                    width: 0;
                }
            }

            &:nth-child(2) a:before {
                border-bottom: 15px solid transparent;
                border-left: 10px solid;
                border-top: 15px solid rgba(0, 0, 0, 0);
                content: "";
                height: 0;
                left: 0;
                position: absolute;
                top: 0;
                width: 0;
            }

            a {
                float: left;
                margin: 0 5px 0 0;
                padding: 5px 10px;
                position: relative;
                text-decoration: none;
                font-size: 14px;

                &:hover {
                    opacity: 0.9;

                    &:after {
                        opacity: 0.9
                    }
                }
            }
        }
    }

    li {
        list-style-type: none;
        border-top: none !important;
        background: transparent !important;
        padding: 0;
        float: left;

        &:hover {
            border-top: none !important;
            background: transparent !important;
        }
    }
}

/*-----------------------------
    PANIERS
-------------------------------*/

.cmonsite-panier-2 {

    header .header-conteneur .menu {
        max-width: 75%;
    }

    #nav-principal > ul {
        max-width: calc(100% - 140px);
    }
}

.template-panier {

    &.template-panier-2 {
        margin-top: 0;
        border: none;
        margin-left: 10px;
        z-index: 10;

        #header-panier {
            text-transform: uppercase;
            padding: 40px 15px 15px;
            font-size: 16px;

            .count-live {
                left: 25px;
                top: 25px;
            }
        }

        #paniercontent {
            top: calc(100% + 5px);
        }
    }

    &.template-panier-3 {
        //width: 1100px;
        //margin: 0 auto;
        //position: fixed;
        //float: none;
        //border: none;
        //right: auto;
        //background: transparent;

        border:none;

        .bloc-connexion-panier {
            width: 275px;
            border-left: 2px solid;
            border-right: 2px solid;
            border-bottom: 2px solid;
            float: right;
        }

        .connexion p a {
            font-size: 1rem;
        }

        #header-panier {
            font-size: 1rem;
            padding: 10px 10px 10px 15px;

            .count-live {
                left: 25px;
            }

            .paniertoggle {
                float: none;
                display: inline-block;
                margin-left: 20px;
            }
        }

        #paniercontent {
            top: calc(100% + 10px);
            right: -2px;
        }
    }
}

/*-----------------------------
    SLIDERS
-------------------------------*/

.accueil_diaporama_template.content {
    min-height: 0;
}

.accueil_diaporama_template {
    width: 1100px;
    margin: 0 auto;

    // Navigation du slider (boutons et fleches)

    .bx-wrapper {
        margin-bottom: 15px;
    }

    &:hover {

        .previous, .next, .bx-prev, .bx-next {
            opacity: 1;
        }

        a.bx-prev {
            left: 30px;
        }

        a.bx-next {
            right: 30px;
        }
    }

    a.bx-prev {
        width: 45px;
        height: 45px;
        padding: 5px;
        border-radius: 50%;
        text-align: center;
        font-size: 0;
        opacity: 0;
        transition: all .1s ease;
        text-indent: 0;
        left: 0;

        &::before {
            content: "\f053";
            font-family: FontAwesome, sans-serif;
            font-size: 35px;
            line-height: 45px;
            margin-left: -5px;
            display: inline-block;
        }

        .fa {
            display: none;
        }
    }

    a.bx-next {
        width: 45px;
        height: 45px;
        padding: 5px;
        border-radius: 50%;
        text-align: center;
        font-size: 0;
        opacity: 0;
        transition: all .1s ease;
        text-indent: 0;
        right: 0;

        &::before {
            content: "\f054";
            font-family: FontAwesome, sans-serif;
            font-size: 35px;
            line-height: 45px;
            margin-right: -5px;
            display: inline-block;
        }

        .fa {
            display: none;
        }
    }

    // Position des éléments

    .slide .slide-text .content-slide-text {

        > p {
            bottom: 20px;
            top: auto;
            left: 0;
            margin: 0 auto;
            right: auto;
            max-width: 70%;
            font-size: 1.1rem;
        }

        > a {
            top: auto;
            bottom: 20px;
            margin-bottom: 0;
        }

        .addcommentaires ~ .slider-see-more {
            right: 105px;
        }
    }

    // Affichage particulier template slider 1

    &.accueil_diaporama_template_1 {

        padding: 15px;

        .bx-viewport, .slide {
            max-width: 1100px;
        }
    }

    // Affichage particulier template slider 2

    &.accueil_diaporama_template_2 {
        width: 100%;
        margin-bottom: 0;

        .bx-wrapper .bx-pager {
            position: relative;
            padding: 0;
            bottom: auto;
        }

        .slide .slide-text .content-slide-text {

            > p {
                bottom: 125px;
                left: 0;
                right: auto;
            }

            > a {
                top: auto;
                bottom: 45px;
                padding: 20px;
                width: auto;
                font-size: 1.1rem;
            }

            .addcommentaires ~ .slider-see-more {
                right: 135px;
            }
        }
    }

    // Affichage particulier template slider 3

    &.accueil_diaporama_template_3 {
        margin: 15px auto;
        padding: 15px;

        .bx-controls-auto {
            display: none;
        }

        #bx-pager > a {
            opacity: 0.3;

            &.active, &:hover {
                opacity: 1;
            }
        }
    }

    // Affichage particulier template slider 4
    &.accueil_diaporama_template_4{
        margin: 0 auto;
        width: 100%;

        .slide .slide-text .content-slide-text {

            > p {
                bottom: 120px;
                right: 40px;
                left: 40px;
            }

            .addcommentaires ~ .slider-see-more {
                right: 125px;
                padding: 20px;
                box-sizing: border-box;
                width: auto;
            }
        }
    }

    // Affichage particulier template slider 5

    &.accueil_diaporama_template_5 {
        margin: 15px auto;
        min-height: 0;
        padding: 15px;

        .slide .slide-text .content-slide-text {

            > p {
                bottom: 65px;
                right: 15px;
                left: 15px;
            }

            > a {
                bottom: 15px;
                right: 20px;
            }

            .addcommentaires ~ .slider-see-more {
                right: 95px;
            }
        }
    }

    &.accueil_diaporama_template_6 {
        .slide .slide-text .content-slide-text {

            > p {
                bottom: 101px;
                right: 40px;
                left: auto;
            }

            .addcommentaires ~ .slider-see-more {
                right: 125px;
                padding: 20px;
                box-sizing: border-box;
                width: auto;
            }
        }
    }
}

/*-----------------------------
   ALBUMS PHOTO
-------------------------------*/

.template_album {

    .galerie {
        overflow: hidden;

        .commentP {
            top: auto;
            bottom: 0;
            transform: none;
        }

        .addcommentaires {
            display: block;
            height: 50px;
            width: 50px;
            font-size: 1rem;
            padding: 14px 0;
            text-align: center;
            border-radius: 50%;
            bottom: auto;
            top: 15px;
            right: -65px;
            transition: all .1s;
            box-sizing: border-box;
            text-decoration: none;

            .fa-comments-o {
                margin-right: 3px;
            }
        }
    }

    .galerie:hover {
        .addcommentaires {
            right: 15px;
        }

        .center-img::after {
            opacity: 1;
        }
    }

    .center-img::after {
        content: '+';
        display: block;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        text-align: center;
        font-size: 100px;
        z-index: 1;
        opacity: 0;
    }

    .mask {
        z-index: 0;
    }

    &.template_album_2 {

        .galerie {

            .commentP p {
                display: inline-block;
                font-size: 1rem;
                position: relative;
                left: 0;
                right: 0;
                text-align: center;
                width: 100%;
            }

            img {
                transform: none;
            }
        }
    }
}

.list-produits .zoom {
    background: url("../images/imgd612/zoom.png") no-repeat;
    bottom: 0;
    display: block;
    height: 23%;
    left: 55px;
    margin-top: 55px;
    position: absolute;
    right: 0;
    text-indent: -9999px;
    top: 0;
    width: 23%;
}

/*-----------------------------
    LIVRE D'OR
-------------------------------*/

.template_livreor {

    .buttonLivre {
        margin: 15px 0;
    }

    &.template_livreor_1 {

        .message_lo.border_p {
            box-sizing: border-box;
            padding: 10px;

            h4, .h4 {
                max-width: 85%;
                margin: 10px 0;

                &::after {
                    content: "";
                    display: block;
                    width: 50px;
                    height: 2px;
                    margin-top: 10px;
                }
            }

            .note {
                max-width: 15%;
            }

            .message {
                background: none;
            }
        }
    }

    &.template_livreor_2 {

        .message_lo:hover .IDLO h4,
        .message_lo:hover .IDLO .h4 {
            text-transform: uppercase;
        }

        .IDLO h4,
        .IDLO .h4 {
            text-transform: uppercase;
        }

        .contenuMsgLO .message {
            background: none;
        }
    }

    &.template_livreor_3 .message {
        background: none;
    }
}

.livre_dor > h2,
.livre_dor > .h2 {
    display: block;
    margin-bottom: 25px;
}

.message_lo {
    border-bottom: 1px solid;
    margin: 25px;

    h4, .h4 {
        width: 80%;
        font-size: 14px;
        float: left;
    }

    .web {
        font-size: 12px;
        font-style: italic;

        a {
            text-decoration: underline;
        }
    }

    .note {
        border-bottom: 3px solid;
        float: right;
        font-size: 19px;
        font-weight: bold;
        height: 25px;
        margin-top: 10px;
        overflow: hidden;
        width: 60px;
    }

    .message {
        clear: both;
        font-size: 13px;
        min-height: 50px;
        padding: 10px;
    }
}

/*-----------------------------
    BOUTIQUE
-------------------------------*/

.template_boutique_all {

    .produits {
        box-sizing: border-box;
        padding: 10px;

        &:hover {
            .zoom {
                opacity: 1;
            }
        }

        .remise {
            padding-top: calc(100% - 10px);
            vertical-align: top;
            top: 0;
            display: inline-block;
            position: absolute;

            &::before {
                border-left: 60px solid transparent;
                border-right: 60px solid transparent;
                border-top: 60px solid;
                content: "";
                right: -82px;
                position: absolute;
                bottom: -8px;
                transform: rotate(405deg);
            }

            > span {
                position: absolute;
                bottom: 10px;
                left: 10px;
                width: 45px;
            }
            span{
                font-size: 1rem;
            }
        }

        .produit_etiquette h3,
        .produit_etiquette .h3 {
            padding: 5px;
        }

        .productImageWrap {
            box-sizing: border-box;

            .zoom::before {
                content: "";
                background: url(../images/imgd612/zoom.png) no-repeat;
                display: block;
                position: absolute;
                height: 32px;
                width: 32px;
                margin: 0 auto;
                transform: translateY(-50%);
            }

            .zoom::after {
                content: "";
                display: block;
                position: absolute;
                height: 100%;
                width: 100%;
                background: #000;
                opacity: .5;
            }
        }

        .nomprod a {
            text-decoration: none;
        }

        .prix {
            font-size: 1.1rem;

            .prixprod {
                float: none;
            }

            .oldprix {
                float: none;
                text-decoration: line-through;
            }
        }

        .attr select {
            cursor: pointer;
        }

        .addbasket, .hors-stock {
            line-height: 29px;
            width: 50%;
            text-decoration: none;
            padding: 5px;
            box-sizing: border-box;
        }
    }

    &.template_boutique_accueil {

        .produits {
            text-align: center;
        }

        .produit_etiquette {
            width: 100%;
            height: auto;

            h3, .h3 {
                font-size: 1.2rem;
                padding: 5px;
            }
        }

        .remise {
            left:10px;

            &::before {
                border-left: 60px solid transparent;
                border-right: 60px solid transparent;
                border-top: 60px solid;
                content: "";
                right: auto;
                position: absolute;
                bottom: -9px;
                transform: rotate(405deg);
                left:-38px;
            }
        }

        .zoom {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            margin: 0;
            background: none;
        }

        .nomprod {
            font-size: 1.1rem;
            text-align: center;
        }

        .prix {
            margin: 10px 0;
        }

        .attr {
            width: 45%;
            float: left;
            display: inline-block;

            select {
                width: 100%;
                height: 29px;
            }
        }

        .addbasket, .hors-stock {
            text-align: center;
            width: 50%;
            float: right;
            margin: 5px 0;
            display: inline-block;
            clear: none;
        }

        &.accueil_boutique_template_1 {

            $largeurFleches: 0px;
            $margin: 20px;
            $nbrProduit: 4;
            @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);

            &:hover .bx-controls-direction {

                a.bx-prev {
                    opacity: 1;
                    left: 10px;
                }

                a.bx-next {
                    opacity: 1;
                    right: 10px;
                }
            }

            .bx-controls-direction {

                a.bx-prev {
                    background: none;
                    text-indent: 0;
                    font-size: 0;
                    text-decoration: none;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    padding: 5px;
                    text-align: center;
                    box-shadow: 0 0 3px #444;
                    opacity: 0;
                    left: 0;
                    transition: all .1s;

                    &::before {
                        content: "\f053";
                        font-family: fontAwesome, sans-serif;
                        font-size: 20px;
                        line-height: 27px;
                        margin-left: -2px;
                    }
                }

                a.bx-next {
                    background: none;
                    text-indent: 0;
                    font-size: 0;
                    text-decoration: none;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    padding: 5px;
                    text-align: center;
                    box-shadow: 0 0 3px #444;
                    opacity: 0;
                    right: 0;
                    transition: all .1s;

                    &::before {
                        content: "\f054";
                        font-family: fontAwesome, sans-serif;
                        font-size: 20px;
                        line-height: 27px;
                        margin-right: -2px;
                    }
                }
            }

            .produits:hover .zoom {
                opacity: 1;
            }

            .attr {
                width: 50%;
                display: block;
                float: none;
                margin: 5px auto;
            }

            .addbasket, .hors-stock {
                padding: 5px 10px;
                float: none;
                width: auto;
            }
        }

        &.accueil_boutique_template_2 {

            .produit_etiquette h3,
            .produit_etiquette .h3 {
                font-size: 1rem;
            }

            .container-etiquette{
                width: 45%;
            }

            .remise {
                padding-top: calc(45% + 8px);
                font-size: .8rem;

                &::before {
                    border-left: 40px solid transparent;
                    border-right: 40px solid transparent;
                    border-top: 40px solid;
                    right: auto;
                    left:-26px;
                    bottom: 1px;
                }
                > span{
                    left: 0;
                }
            }

            .productImageWrap {
                width: 45%;
                float: left;
            }

            .nomprod {
                width: 50%;
                float: right;
            }

            .prix {
                width: 50%;
                float: right;
                margin-left: 1px;

                .prixprod {
                    display: block;
                    margin: 5px 0;
                }

                .oldprix {
                    display: block;
                    margin: 0;
                }
            }

            .attr {
                width: 50%;
                height: 29px;
                line-height: 29px;
                float: right;
                margin-bottom: 10px;

                &:empty {
                    display: block;
                }
            }

            .desc {
                display: block;
                clear: both;
                width: 100%;
                margin: 10px 0;
            }

            .addbasket, .hors-stock {
                float: none;
                width: auto;
                font-size: 1rem;
                padding: 5px 15px;
            }
        }
    }

    &.template_boutique {

        &.template_boutique_1 {

            .nomprod a {
                font-size: 1.3rem;
            }

            .attr{
                margin-left:0;
            }

            .prixprod, .oldprix {
                float: none;
            }

            .addbasket, .hors-stock {
                font-size: .8rem;
                text-decoration: none;
                &.next-select{
                    margin-right:0;
                }
            }

            .block-btn-addbasket{
                align-items:flex-end ;
                justify-content:flex-start;
                margin: 0;
            }
        }

        &.template_boutique_2 {

            .container-etiquette {
                font-size: 1.5rem;
            }

            .productContentWrap {
                text-align: center;
            }

            .nomprod {
                margin: 10px 0;

                a {
                    font-size: 1.5rem;
                }
            }

            .prix {

                .prixprod {
                    font-size: 1.8rem;
                    margin-right: 0;
                }

                .oldprix {
                    font-size: .8rem;
                }
            }

            .attr {
                line-height: 39px;
                width: 45%;
                float: none;
                margin: 5px auto;

                select {
                    height: 39px;
                    width: 100%;
                    right: 0;
                    padding: 5px;
                }
            }

            .addbasket, .hors-stock {
                width: 50%;
                float: right;
                margin: 5px 0;
                font-size: 1.2rem;
                box-sizing: border-box;
                padding: 5px 0;
                height: auto !important;
            }
        }

        &.template_boutique_3 {

            .produits {
                text-align: left;

                .container-etiquette {
                    font-size: 1.4rem;
                }

                .remise {
                    padding-top: calc(30% + 4px);
                    left:10px;
                }

                &.is_etiquette .remise {
                    padding-top: calc(30% + 4px);
                }

                .nomprod a {
                    font-size: 1.5rem;
                }

                .desc {
                    font-size: 1.1rem;
                }

                .prix {
                    text-align: left;
                    font-size: 1.3rem;
                }

                .attr {
                    text-align: left;

                    select {
                        float: none;
                        padding: 5px;
                    }
                }

                .addbasket, .hors-stock {
                    font-size: 1.2rem;
                    position: relative;
                    display: inline-block;
                    bottom: 0;
                    line-height: 1.2rem;
                    padding: 10px 20px;
                    width: auto;
                }
            }
        }

        &.template_boutique_4 {

            .container-etiquette {
                position: absolute;
                top: 10px;
                left: 10px;
                right: 10px;
                margin: 0;
            }

            .productImageWrap {
                position: relative;
                .zoom::before{
                    top:50%;
                    padding-top:0;
                }
            }

            .nomprod a {
                font-size: 1.5rem;
                line-height: 1.2em;

            }

            .attr select {
                padding: 5px;
            }

            .addbasket, .hors-stock {
                width: 100%;
                margin: 5px 0 0;
                font-size: 1.2rem;
                box-sizing: border-box;
                padding: 5px 0;
                height: auto !important;
            }
            .note-produit {
                text-align: center;
            }
        }

        &.template_boutique_5 {

            .produits {

                .container-etiquette {
                    position: absolute;
                    width: 45%;
                    left: 0;
                    padding-left: 11px;
                    .h3{
                        font-size: 1rem;
                    }
                }

                .remise {
                    padding-top: calc(45% + 8px);

                    &::before {
                        border-left: 40px solid transparent;
                        border-right: 40px solid transparent;
                        border-top: 40px solid;
                        right: -55px;
                        position: absolute;
                        bottom: 1px;
                    }

                    span {
                        bottom: 12px;
                        left: 7px;
                        font-size: .8rem;
                    }
                }

                .productImageWrap {
                    width: 45%;
                }

                .productContentWrap {

                    .nomprod {
                        width: 50%;
                        a {
                            font-size: 1.1rem;
                        }
                    }

                    .desc {
                        display: block;
                        margin:10px 0;
                        clear: both;
                    }

                    .prix {
                        width: 50%;
                    }

                    .attr {
                        width: 50%;
                        height: 39px;
                        line-height: 39px;
                        margin-bottom: 25px;

                        &:empty::after {
                            height: 39px;
                        }

                        select {
                            padding: 5px;
                        }
                    }

                    .addbasket, .hors-stock {
                        width: auto;
                        font-size: 1rem;
                        margin: 15px 0 0 0;
                        padding: 5px 15px;
                        clear: both;
                        line-height: 29px;
                    }
                }
                .note-produit {
                    text-align: center;
                }
            }
        }
    }
}

/*-----------------------------
    FICHE PRODUIT
-------------------------------*/

// On cache le titre de la page sur les fiches produit pour éviter le doublon de nom produit

.cmonsite-ficheproduit #bloc-fil-title {

    margin: 10px 0;

    h2, .h2, h1, .h1 {
        display: none;
    }
}

// Structure globale fiche produit

.template_fiche_produit {

    .wrap-details h4::after,
    .wrap-details .h4::after {
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        background: #555;
        margin-top: 10px;
    }

    #fiche-produit {

        .wrap-description .h1 {
            margin-top:0;
        }
        .brand{
            margin-top: 0;
        }

        h2[itemprop="brand"] {
            border: none;
            font-size: 1rem;
            &::after {
                display: none;
            }
        }

        .triangle-ficheprod .remise-produit {
            right: auto;
            top: calc(100% - 30px);
            width: auto;
            border-radius: 0;
            font-size: 1rem;
            padding: 0 10px;

            &::before {
                border-left: 60px solid transparent;
                border-right: 60px solid transparent;
                border-top: 60px solid;
                transform: rotate(405deg);
                z-index: -1;
                left: -19px;
                top: -22px;
                content: "";
                display: block;
                height: 0;
                margin-left: -20px;
                position: absolute;
                width: 0;
            }
        }

        .remise-produit {
            right: 0;
            top: 50px;
            border-radius: 10px 0 0 10px;
            z-index: 100;
            font-weight: bold;
            width: auto;
            height: auto;
            padding: 15px 10px;
            font-size: 12px;
        }

        .wrap-images {
            box-sizing: border-box;

            .previous, .bx-prev {
                text-align: center;
                font-size: 0;
                width: 20px;
                opacity: 0;
                transition: all .2s ease;
                text-indent: 0;

                &::before {
                    content: "\f053";
                    font-family: FontAwesome, sans-serif;
                    font-size: 22px;
                    display: inline-block;
                    float: left;
                }

                .fa {
                    display: none;
                }
            }

            .next, .bx-next {
                text-align: center;
                font-size: 0;
                width: 20px;
                opacity: 0;
                transition: all .2s ease;
                text-indent: 0;

                &::before {
                    content: "\f054";
                    font-family: FontAwesome, sans-serif;
                    font-size: 22px;
                    display: inline-block;
                    float: right;
                }

                .fa {
                    display: none;
                }
            }

            .previous, .next, .bx-prev, .bx-next {
                background: none;
            }

            &:hover {
                .previous, .next, .bx-prev, .bx-next {
                    opacity: 1;
                }
            }

            .grande-image {
                box-sizing: border-box;
            }

            .previews {

                ul li {
                    box-sizing: border-box;
                }
            }
        }

        .wrap-description {

            .declinaison-attribute-group,.product_options-group {
                text-align: left;

                .radio_unique {
                    float: none;
                }
            }

            label {
                font-size: .8rem;
                margin-right: 10px;
                margin-bottom:0;
            }

            .radio_unique {
                cursor: pointer;
                margin: 10px 10px 10px 0;
                border-radius: 0;
                box-shadow: none;
            }

            .radio_color {
                box-shadow: none;
                border-radius: 0;
            }

            .prix {
                height: 30px;
                line-height: 30px;

                .prixprod {
                    margin-right: 15px;
                }
            }

            select {
                min-height: 30px;
                padding: 5px;
            }

            .row {
                margin-top: 0;
                margin-bottom: 10px;
            }

            .note-produit {
                margin: 10px 0;
            }

            .pro-pannier {
                display: flex;
                flex-direction: column;
            }

            .declinaison {
                order: 1;
            }

            .bloc-quantite {
                order: 3;
                min-height: 36px;
                line-height: 36px;
                width: 100%;

                .bt-quantity{
                    height: auto;
                }
            }

            .ajouter-panier {
                &.block-price{
                    order: 2;
                }
                &.block-addbasket{
                    order: 4;
                    margin-top: 10px;
                }


                .triangle-ficheprod {
                    display: none;
                }

                .addbasket {
                    display: inline-block;
                    width: auto;
                    font-size: 1rem;

                    .fa-shopping-cart {
                        margin-right: 10px;
                    }
                }
            }

            .details-ficheprod a.button {
                display: inline-block;
                width: auto;
            }

            .description {
                font-size: 1rem;
            }

            .details-ficheprod {
                font-size: 1rem;
            }
        }

        .bloc-description-detaille {
            box-sizing: border-box;
        }

        .wrap-details h4::after,
        .wrap-details .h4::after {
            content: "";
            display: block;
            width: 50px;
            height: 2px;
            margin-top: 10px;
        }

        .wrap-details.bloc-avis-clients {

            .button {
                width: auto;

                .fa-comment {
                    margin-right: 10px;
                }
            }
        }

        h4, .h4 {
            margin: 10px 0;
            text-align: left;
            text-transform: capitalize;
        }

        #addcomment {
            width: 100%;

            #formulaire button {
                border: none;
            }
        }

        .bloc-avis-clients {

            .moyenne_etoiles {
                margin: 0 15px;
            }

            .msg-informations {
                width: 100%;
                padding-bottom: 10px;
                margin-bottom: 20px;

                .noteComment {
                    float: right;
                    font-size: 20px;
                }

                span {
                    display: inline-block;
                }

                .time {
                    margin-right: 10px;
                    font-size: .9rem;
                }

                .msg-username {
                    font-weight: bold;
                }
            }
        }
    }

    &.template_fiche_produit_1 #fiche-produit {

        .productImageWrap {
            box-sizing: border-box;
        }

        .wrap-description .ajouter-panier {
            position: relative;


        }
    }

    // Affichage particulier template fiche produit 2

    &.template_fiche_produit_2 #fiche-produit {

        .container-etiquette {
            margin-bottom: 0;

            .produit_etiquette::after {
                display: none;
            }
        }

        .note-produit {
            margin: 0;
        }

        h1, .h1 {
            font-size: 1.5rem;
        }

        .bx-wrapper {
            margin: 0;
            .sliderImageFicheProd{
                margin:0;
            }
        }

        .zoom-img-position{
            display: inline-block;
            width: 100%;
        }

        .wrap-images {
            padding-top: 0;

            .triangle-ficheprod {
                right: 0;
            }
            .remise-produit {
                right: -10px;
                left: auto;
                height: auto;
            }

            .productImageWrap {
                box-sizing: border-box;
            }

            .triangle-ficheprod {
                right: 0;
                top: 0;

                .remise-produit {
                    right: 0;
                    top: 5px;

                    &::before {
                        transform: rotate(225deg);
                        left: -3px;
                        top: -13px;
                    }
                }
            }
        }

        .wrap-description {

            h1, .h1 {
                margin: 15px 0;
            }

            width: 35%;
            box-sizing: border-box;
            padding: 0 0 0 15px;

            .ajouter-panier {
                text-align: left;
            }



            #produit-comment .commentFicheProd {
                padding-right: 0;
            }

            .details-ficheprod {
                display: none;
            }
        }

        .noteGlobale {
            padding: 0;
        }

        .wrap-details {
            padding: 0;
            font-size: 1rem;

            &.bloc-avis-clients .msg-informations {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        .grande-image {
            border: none;
        }



        .bloc-description-detaille {
            padding: 10px 0;

            h4, .h4 {
                padding-bottom: 10px;
            }

            p {
                margin: 10px 0;
                text-align: justify;
            }
        }

        .noteGlobale {
            box-sizing: border-box;
            padding: 10px;
        }

        #addcomment {
            padding: 10px;
        }
    }

    // Affichage particulier template fiche produit 3

    &.template_fiche_produit_3 #fiche-produit {

        h1, .h1 {
            font-size: 1.5rem;
        }

        .produit_etiquette::after {
            display: none;
        }

        .triangle-ficheprod {
            top: 100%;
            left: auto;
            right: 0;
            position: absolute;

            .remise-produit {
                right: 0;
                left: auto;

                &::before {
                    transform: rotate(-45deg);
                    left: -4px;
                    top: -21px;
                }
            }
        }
        .bloc-quantite {
            order: 2;
            margin-top: 8px;
            width: 100%;


        }

        .ajouter-panier {
            order: 3;
        }

        .declinaison {
            order: 4;
        }

        .prix {
            order: 1;
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;

            .oldprix, .prixprod {
                display: inline-block;
                width: auto;
                margin: 0 10px;
            }
        }

        .wrap-description {

            .declinaisons-achat .pro-pannier .ajouter-panier .addbasket {
                width: auto;
                margin: 0 0 0 120px;

                .fa-shopping-cart {
                    margin-right: 10px;
                }
            }

            .details-descr-com {

                .side-tabs {
                    width: 100%;

                    > a {
                        width: calc(100% / 3);
                        box-sizing: border-box;
                        clear: none;
                        display: inline-block;
                        border-radius: 0;
                        border-bottom: none;
                        border-top: none;
                        border-left: none;
                        margin: 0;
                        float: left;
                        font-size: 1rem;
                        height: 40px;
                        line-height: 37px;

                        &:last-child {
                            border-right: none;
                        }
                    }
                }

                .in-onglet {
                    width: 100%;
                    padding: 15px 15px 0;
                    margin-left: 0;

                    p {
                        font-size: 1rem;
                    }
                }
            }

            .sociaux {
                border-bottom: none;
            }
        }

        .bloc-avis-clients .noteGlobale,
        .bloc-avis-clients #addcomment {
            background: none;
        }
    }
}



#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > .button {
        flex: 0 1 auto;
        margin: 15px 0;
        display: inline-block;
    }

    > .button + .button {
        margin-left: 15px;
    }

}


.modal {
    z-index: 9999;
}

.modal-backdrop {
    z-index: 9998;
}

/*-----------------------------
    CONTACT
-------------------------------*/

.form-newsletter div:last-child{
    width: 100%;
}

.template_contact{
    h2{
        margin-bottom: 25px;
    }
}

input.button[type="submit"]{
    border: none;
}

form, .template_contact form, #fiche-produit form{
    margin-top:25px;
    .form-group{
        .qs_form{
            border:none;
        }
        input{
            background-image:none !important;
            &.hasDatepicker{
                max-width: 170px;
                margin: 0 5px;
                display: inline-block;
            }
            &[type="password"]{
                margin-top: 3px;
            }
        }
        input[type="text"]:not(.button):not([type="file"]), textarea, input[type="password"],select{
            padding:5px;
            font-size: 1.1rem;
            box-sizing: border-box;
            border-radius: 0;
            &:focus, &:hover{
                border-style:solid;
            }
        }
        input[type="file"]{
            background:transparent !important;
            border:none !important;
        }
        textarea{
            min-height: 250px;
        }
        label:first-child{
            margin-top:10px;
            margin-bottom: 10px;
        }
        .groupe-radio, .groupe-checkbox{
            text-align: left;
            position:relative;
            label{
                margin-left: 0;
                &::before{
                    content:'';
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    background:transparent;
                    border:2px solid;
                    border-radius:1px;
                    vertical-align: middle;
                    box-sizing: border-box;
                    margin: 8px 5px;
                }
            }
            input{
                position: absolute;
                opacity: 0;
            }
        }
        .groupe-radio label::before{
            border-radius:50%;
        }
    }
}

.template_fiche_produit_2 {

    .datepicker__month {
        table-layout: fixed;
    }

    .datepicker__month {

        font-size: 11px;
    }

    .datepicker__week-name {
        font-size: 9px;
    }

    .datepicker__info--edit, .datepicker__topbar .reservation__filter {
        width: 30%;
    }

    .datepicker__info--selected {
        width: 70%;
    }
    #fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket>.button {
        flex:0 1 auto;
        margin:15px 0;
        display:inline-block;
        font-size: 1.2rem;
    }

}



.template_contact{
    &.template_contact_5 form .form-group{
        .groupe-radio, .groupe-checkbox{
            text-align: center;
        }
    }
}

/*-----------------------------
    BLOG
-------------------------------*/

.blog-sidebar {
    .blog-sidebar-bloc ul{
        padding-left:0;
    }
    a{
        text-decoration:none;
    }
}

.apercu-article{
    a{
        text-decoration:none;
    }
}

.single-article .titre-article h2, .single-article .titre-article .h2{
    &::after{
        left: 0;
    }
}

/*-----------------------------
    PIED DE PAGE
-------------------------------*/

footer {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 15px;
    box-sizing: border-box;

    .mention {
        padding-bottom: 10px;
        padding-top: 10px;
        clear: both;

        a {
            text-decoration: none;
            font-size: 10px;
        }
    }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
    .side-tabs.etapes,
    div#resume .block-recap-panier,
    div#resume div.next-step,
    div#information #connexion-inscription,
    div#information div.next-step,
    #infoscommande,
    div#finish .block-info-payment,
    div#finish div.next-step,
    div#resume h4,
    div#finish h4,
    .message-commande {
        width: 95%;
    }

    .logo-carrier-none {
        width: auto;
    }

    h4 {
        margin: 0;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }
}

/*-----------------------------
    Boutons profil
-------------------------------*/

#order .commandes td{
    padding:10px 0;
    border-top:1px solid rgba(128,128,128,.1);
    .button{
        margin: 0 5px 0 0 !important;
    }
}
#order .commandes-details.s6{
    > td > div{
        border-bottom:none;
    }
}

/*-----------------------------
    MEDIA QUERIES
-------------------------------*/

@media screen and (max-width: 1100px) {

    #minisite-sidebar.pos-right {
        display: none;
    }

    #block-flottant-search {
        display: none;
    }

    header .header-conteneur #blocContent {
        position: relative;
    }

    header .header-conteneur,
    .accueil_diaporama_template {
        width: 100%;
    }



    #main-conteneur header {
        padding: 10px 15px;
        box-sizing: border-box
    }

    .template-panier.template-panier-3 {
        right: 20px;
    }

    header .header-conteneur #blocContent {
        width: 100%;
    }

    header .header-conteneur .menu {
        top: 20px;
    }

    .cmonsite-panier-3 header .header-conteneur .menu {
        top: 55px;
    }

    .template-panier.template-panier-2 {
        margin-top: 0;
        right: 95px;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        float: none;

        #header-panier {
            top: 0;
            padding: 13px;

            .fa-shopping-cart {
                vertical-align: baseline;
                font-size: 22px;
            }
        }
    }

    /* Nav mobile */

    header .header-conteneur .menu {
        position: absolute;
        right: 0;
        width: 100%;

        nav#nav-principal {
            float: none;
            margin: 0 auto 10px;
            position: relative;
            width: 95%;
            z-index: 10000;

            .menu_burger {
                padding: 10px 15px;
                cursor: pointer;
                display: block;
                font-size: 16px;
                font-weight: bold;
                margin: 0 20px;
                width: 27px;
                float: right;
                &::before {
                    font-family: FontAwesome, sans-serif;
                    content: "\f0c9";
                    margin-right: 5px;
                    font-size: 30px;
                }
            }

            a {
                padding: 4px 24px;
            }

            > ul {
                position: absolute;
                right: 10px;
                max-width: 300px;
                box-shadow: 0 4px 2px rgba(11, 25, 28, 0.3);
                margin: 1px 0 0 0;
                display: none;
                width: 100%;
                top:100%;
                z-index: 1000;

                > li {
                    display: block;
                    text-align: left;
                    margin: 0;
                    padding-left: 0;
                    box-sizing: border-box;
                    float: none;

                    a{
                        padding: 15px;
                        display: block;
                        span {
                            font-size: 17px;
                        }
                    }

                    ul {
                        position: relative;
                        box-sizing: border-box;
                        box-shadow: none;
                        display: block;
                        text-align: left;
                        border: none;
                        opacity: 1;
                        top: 0;
                        left:0;
                        padding-left: 0;
                        height: auto !important;
                        width: 100% !important;
                        visibility: visible !important;

                        li > ul{
                            li{
                                padding-left: 15px;
                            }
                        }

                        li:hover > ul{
                            left:0;
                            top:0;
                        }
                    }
                }
            }

            .hasDropdown ul + span::after {
                font-family: FontAwesome, sans-serif;
                content: "\f0d7";
            }
        }
    }

    .dropDown {
        position: absolute;
        right: 0;
        top: 0;
        font-weight: bold;
        display: block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        padding-top: 0;
        cursor: pointer;
        text-align: center;
        font-size: 1.2rem;
        color:inherit;
    }

    /* FIN Nav mobile */

    .template_boutique.template_boutique_5 {

        .productImageWrap {
            height: auto;
        }

        .zoom {
            display: none;
        }
    }

    .template_fiche_produit.template_fiche_produit_2 #fiche-produit {

        .container-etiquette {
            display: none;
        }

        .wrap-description {
            width: 100%;
        }

        //Pour passer au-dessus du JS
        .zoomWindow{
            left:auto !important;
            right:0 !important;
            top:0 !important;
            bottom:auto !important;
        }
    }
}

@media #{$medium-and-down}{
    .template_boutique_all {
        &.template_boutique_accueil {
            &.accueil_boutique_template_1 {
                $largeurFleches: 0px;
                $margin: 20px;
                $nbrProduit: 2;
                @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
            }
        }
    }
}

@media #{$small-and-down}{
    #formulaire{
        .input-form-moyen, .input-form-petit{
            width: 100%;
        }
    }
    .template-panier.template-panier-3{
        position: absolute;
        .bloc-connexion-panier{
            border-top:2px solid;
        }

        .paniertoggle .fa{
            right:7px;
        }
    }
    .template_boutique_all {
        &.template_boutique_accueil {
            &.accueil_boutique_template_1 {
                $largeurFleches: 0px;
                $margin: 0px;
                $nbrProduit: 1;
                @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
            }
        }
        &.template_boutique{
            &.template_boutique_5{
                .produits{
                    .productImageWrap{
                        width: 100%;
                    }
                    .container-etiquette{
                        padding-right:11px;
                        width: 100%;
                    }
                    .remise{
                        padding-top:calc(100% - 4px);
                    }
                    .productContentWrap{
                        .nomprod, .prix{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .template_album{
        &.template_album_2,
        &.template_album_4,
        &.template_album_5{
            .galerie{
                img{
                    width: 100%;
                }
                .commentP{
                    opacity:1;
                }
            }
        }
    }


    @import 'design/helper/responsive/mobile/mobile';

}

@media screen and (max-width: 640px) {
    .template-panier.template-panier-2 #paniercontent {
        right: -83px;
    }

    .template_boutique_all.template_boutique_accueil .produits {
        margin: 0 0 15px;
    }

    .template_boutique_all.template_boutique.template_boutique_1 .produits {

        .nomprod {
            margin: 10px 0;
        }

        .attr {
            position: relative;
        }

        .addbasket, .hors-stock {
            float: none;
        }
    }

    .template_boutique_all.template_boutique.template_boutique_3 .produits {

        .productContentWrap {
            text-align: center;
        }

        .remise {
            padding-top: calc(100% - 10px);
        }

        &.is_etiquette {
            .remise {
                padding-top: calc(100% + 33px);
            }
        }

        .desc {
            margin: 15px 0;
            text-align: left;
        }

        .prix {
            text-align: center;
        }

        .attr {
            width: 50%;
            margin: 0 auto;
        }
    }
}

